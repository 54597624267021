import './App.css';
import LoginComponent from './components/LoginComponent ';
import WaitingComponent from './components/WaitingComponent ';

function App() {
  return (
    <div>
      <WaitingComponent></WaitingComponent>
    </div>
  );
}

export default App;
