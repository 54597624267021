import React from 'react';
import '../css/WaitingComponent.css';

const WaitingComponent = () => {
  return (
    <div className="waiting-container">
      <div className="waiting-text-wrapper">
        <div className="waiting-text">
          Community Extravaganza: A Place Where You Belong!
        </div>
        <div className="coming-soon-text">
          Coming soon!
        </div>
        <div className="main-button-container">
          <button className="main-button">Pre-Register</button>
        </div>
      </div>
    </div>
  );
}

export default WaitingComponent;
